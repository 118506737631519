export function IsMobile(type = 0) {
  var w = window.innerWidth;

  let zoom = window.devicePixelRatio * 100;

  document.body.classList.remove("secondaryMenuPositionU5");
  document.body.classList.remove("secondaryMenuPosition45");
  document.body.classList.remove("zoom");
  document.body.classList.remove("zoom-125");
  document.body.classList.remove("zoom-150");

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  let device = toMatch.some((toMatchItem) => {
    // console.log(navigator.userAgent.match(toMatchItem), toMatchItem)
    return navigator.userAgent.match(toMatchItem);
  });

  // console.log(device);

  if ((device || type == 1) && w < 1400) {
    // Smartphone
    document.body.style.zoom = "100%";
    document.body.classList = "mobile";
    return true;
  }
  // console.log(zoom);
  if (zoom > 100) {
    let diff = zoom - 100;
    let sub = 20;
    if (parseInt(zoom) == 110) {
      document.body.classList.add("secondaryMenuPositionU5");
      document.body.classList.add("zoom");
    }
    if (parseInt(zoom) == 125) {
      document.body.classList.add("zoom-125");
    }
    if (parseInt(zoom) == 150) {
      sub = 30;
      document.body.classList.add("secondaryMenuPosition45");
      document.body.classList.add("zoom-150");
    }
    document.body.style.zoom =
      "calc(" + zoom + "% - " + diff + "% - " + sub + "%)";
  }

  return false;
}
