<template>
  <div id="ModalTimeConfirmation" v-if="viewModal" class="modal-overlay">
    <div class="modal contentModal" style="width: 410px; height: auto">
      <span
        @click="closeModal"
        class="material-icons closeOrder"
        id="closeModalX"
        >close</span
      >
      <div class="modal-content-warn">
        <h2 style="text-align: center; margin-bottom: 1rem">
          {{ $t("accessDc.timeConfirmationModal.title") }}
        </h2>
        <p class="subtitle-modal">
          {{ $t("accessDc.timeConfirmationModal.sub") }}:
          {{ sharedRackNames.join(", ") }}
        </p>
        <!-- Checkbox -->
        <div class="checkboxContainer">
          <input type="checkbox" id="timeAgreement" v-model="isChecked" />
          <label for="timeAgreement">{{
            $t("accessDc.timeConfirmationModal.checkboxLabel")
          }}</label>
        </div>
      </div>
      <!-- Buttons -->
      <div class="contentButton widthButtonModal">
        <button
          id="btnModal"
          class="cdlButton"
          @click="confirmAndProceed"
          :disabled="!isChecked"
          style="width: 100%"
        >
          {{ $t("accessDc.timeConfirmationModal.btnContinue") }}
        </button>
        <br /><br />
        <div>
          <p class="deleteOrder" id="deleteOrder" @click="closeModal">
            {{ $t("accessDc.timeConfirmationModal.btnCancel") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/MyModal.scss"; // Ensure this SCSS file contains necessary styles

export default {
  name: "ModalTimeConfirmation",
  props: {
    viewModal: {
      type: Boolean,
      default: false,
    },
    sharedRackNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    closeModal() {
      this.isChecked = false;
      this.$emit("close");
    },
    confirmAndProceed() {
      this.isChecked = false;
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";
@import "@/assets/scss/MyModal.scss";
/* Ensure that these classes have the correct styles either here or in the imported SCSS files */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  background-image: url("@/assets/img/card_order.png");
  width: 770px;
  height: 897px;
  max-width: 90%; /* Responsive */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.closeOrder {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.modal-content-warn {
  text-align: center;
}

.checkboxContainer {
  margin: 20px 0;
  display: flex;
  align-items: center; /* This keeps the checkbox and label vertically aligned */
  justify-content: flex-start; /* Aligns items to the start of the container */
}

.checkboxContainer input[type="checkbox"] {
  width: 20px;
  height: 20px;
  flex-shrink: 0; /* Prevents the checkbox from shrinking when there's a lot of text */
}

.checkboxContainer label {
  margin-left: 0;
  font-size: 16px;
  flex-grow: 1; /* This allows the label to take up the remaining space */
  line-height: 1.2; /* Adjust line height to make text look better on multiple lines */
  word-wrap: break-word; /* Ensures the text wraps to the next line when necessary */
}

#btnModal[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.contentButton {
  text-align: center;
}

.deleteOrder {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
</style>
