<template>
  <div>
    <!-- Tipo di richiesta* -->
    <div class="mdl-grid padding0">
      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo">Tipo di richiesta*</span>
        <div class="contentSelect">
          <select
              name="tipo_di_richiesta"
              @change="updateField('tipo_di_richiesta', fields.tipo_di_richiesta)"
              v-model="fields.tipo_di_richiesta"
          >
            <option value="">Seleziona Tipo di richiesta</option>
            <option
                v-for="option in tipo_di_richiesta_options"
                :key="option.id"
                :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <span
              class="error marginLeft8"
              v-if="
              v$.fields.tipo_di_richiesta.$invalid &&
              v$.fields.tipo_di_richiesta.$dirty &&
              v$.fields.tipo_di_richiesta.required.$invalid
            "
          >
            Tipo di richiesta è richiesto
          </span>
        </div>
      </div>

      <!-- Livello tecnico* -->
      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo">Livello tecnico*</span>
        <div class="contentSelect">
          <select
              name="livello_tecnico"
              @change="updateField('livello_tecnico', fields.livello_tecnico)"
              v-model="fields.livello_tecnico"
          >
            <option value="">Seleziona Livello tecnico</option>
            <option
                v-for="option in livello_tecnico_options"
                :key="option.id"
                :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <span
              class="error marginLeft8"
              v-if="
              v$.fields.livello_tecnico.$invalid &&
              v$.fields.livello_tecnico.$dirty &&
              v$.fields.livello_tecnico.required.$invalid
            "
          >
            Livello tecnico è richiesto
          </span>
        </div>
      </div>
    </div>

    <!-- Urgenza* and Finestra temporale* -->
    <div class="mdl-grid padding0">
      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo">Urgenza*</span>
        <div class="contentSelect">
          <select
              name="urgenza"
              @change="updateField('urgenza', fields.urgenza)"
              v-model="fields.urgenza"
          >
            <option value="">Seleziona Urgenza</option>
            <option
                v-for="option in urgenza_options"
                :key="option.id"
                :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <span
              class="error marginLeft8"
              v-if="
              v$.fields.urgenza.$invalid &&
              v$.fields.urgenza.$dirty &&
              v$.fields.urgenza.required.$invalid
            "
          >
            Urgenza è richiesta
          </span>
        </div>
      </div>

      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo">Finestra temporale*</span>
        <div class="contentSelect">
          <select
              name="finestra_temporale"
              @change="updateField('finestra_temporale', fields.finestra_temporale)"
              v-model="fields.finestra_temporale"
          >
            <option value="">Seleziona Finestra temporale</option>
            <option
                v-for="option in finestra_temporale_options"
                :key="option.id"
                :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <span
              class="error marginLeft8"
              v-if="
              v$.fields.finestra_temporale.$invalid &&
              v$.fields.finestra_temporale.$dirty &&
              v$.fields.finestra_temporale.required.$invalid
            "
          >
            Finestra temporale è richiesta
          </span>
        </div>
      </div>
    </div>

    <!-- Inizio attività* and Purchase Order -->
    <div class="mdl-grid padding0">
      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo">Inizio attività*</span>
        <div class="cdl-input" style="margin-top: 42px">
          <input
              type="datetime-local"
              name="inizio_attivita"
              v-model="fields.inizio_attivita"
              @change="updateField('inizio_attivita', fields.inizio_attivita)"
              step="300"
          />
          <span
              class="error"
              v-if="
              v$.fields.inizio_attivita.$invalid &&
              v$.fields.inizio_attivita.$dirty &&
              v$.fields.inizio_attivita.required.$invalid
            "
          >
            Inizio attività è richiesto
          </span>
        </div>
      </div>
    </div>

    <!-- Sommario* -->
    <div class="mdl-grid padding0">
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo">Sommario*</span>
        <div class="cdl-textarea">
          <textarea
              maxlength="255"
              name="sommario"
              v-model="fields.sommario"
              @change="updateField('sommario', fields.sommario)"
          ></textarea>
          <label>Sommario*</label>
          <span
              class="error"
              v-if="
              v$.fields.sommario.$invalid &&
              v$.fields.sommario.$dirty &&
              v$.fields.sommario.required.$invalid
            "
          >
            Sommario è richiesto
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "OptionalFields",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: {
        tipo_di_richiesta: "",
        livello_tecnico: "",
        urgenza: "",
        finestra_temporale: "",
        inizio_attivita: "",
        purchase_order: "",
        sommario: "",
      },
      // Mock data for select options
      tipo_di_richiesta_options: [
        { id: 1, value: 'Riavvio server', label: 'Riavvio server' },
        { id: 2, value: 'Sostituzione ATS', label: 'Sostituzione ATS' },
        { id: 3, value: 'Etichettatura', label: 'Etichettatura' },
        { id: 4, value: 'Altro', label: 'Altro' },
        { id: 5, value: 'Altro (no SLA)', label: 'Altro (no SLA)' },
      ],
      livello_tecnico_options: [
        { id: 1, value: 'B - Normale', label: 'B - Normale' },
        { id: 2, value: 'A - Avanzato', label: 'A - Avanzato' },
      ],
      urgenza_options: [
        { id: 1, value: 'P - Pianificato', label: 'P - Pianificato' },
        { id: 2, value: 'U - Urgente', label: 'U - Urgente' },
      ],
      finestra_temporale_options: [
        { id: 1, value: 'BT - orario ufficio', label: 'BT - orario ufficio' },
        { id: 2, value: 'ETB - Fuori orario ufficio', label: 'ETB - Fuori orario ufficio' },
      ],
    };
  },
  validations() {
    return {
      fields: {
        tipo_di_richiesta: { required },
        livello_tecnico: { required },
        urgenza: { required },
        finestra_temporale: { required },
        inizio_attivita: { required },
        sommario: { required },
      },
    };
  },
  methods: {
    updateField(fieldName, value) {
      this.$emit("updateField", { fieldName, value });
    },
  },
  created() {
    // Initialize fields with initial data if provided
    Object.assign(this.fields, this.initialData);
    // No need to fetch options since we're using mock data
  },
};
</script>



