<script setup>
import MyCrossStep1 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1.vue";
import MyCrossStep2 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2.vue";
import MyCrossStep3 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3.vue";
import MyCrossStep4 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4.vue";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import HorisontalArrow from "@/components/OrderNetworkCoverage/svg/cross-svg/HorisontalArrow.vue";
import RedundancyComponent from "@/components/CrossConnect/ToggleSwitch.vue";
import CrossStep from "@/components/CrossConnect/CrossStep.vue";
import LogoIrveos from "@/components/OrderNetworkCoverage/svg/cross-svg/LogoIrveos.vue";
import CrossCardInfra from "@/components/CrossConnect/CrossCardInfra.vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";

import CrossStep1InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1InCorso.vue";
import CrossStep1Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1Completato.vue";
import CrossStep2InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2InCorso.vue";
import CrossStep2Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2Completato.vue";
import CrossStep3InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3InCorso.vue";
import CrossStep3Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3Completato.vue";
import CrossStep4InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4InCorso.vue";
import CrossStep4Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4Completato.vue";
import CrossCard from "@/components/CrossConnect/CrossCard.vue";
import MyCrossRefTicket from "@/components/Users/CrossRefTicket.vue";
import MyLoading from "@/components/MyLoading.vue";
import FormsHeader from "@/components/CrossConnect/Title/FormsHeader";
import ButtonsGroupComponent from "@/components/CrossConnect/Buttons/ButtonsGroupComponent.vue";
import AEndComponent from "@/components/CrossConnect/CrossFormComponents/AEndComponent.vue";
import BEndComponentInfra from "@/components/CrossConnect/CrossFormComponents/BEndComponentInfra.vue";

const router = useRouter();
const datacenterStore = useDatacenterStore();
// Access the global properties directly within setup
const { config } = getCurrentInstance().appContext;
const isMobile = ref(false);
// const selectedOption = ref('Seleziona');
const isCardSelected = ref(false);
const isKitSelected = ref(false);
// New reactive variable to control the visibility of CrossCardInfra
const isDataCenterCardSelected = ref(false);
const showContent = ref(false);
const showContentInside = ref(false);

// Refs for selected IDs
const selectedDatacenterId = ref(null);

const isDataLoading = ref(true);
const isClicked = ref(false);

// Optionally, watch the xConInfo array to automatically update the loading state
watch(
  () => datacenterStore.xConInfo,
  (newVal) => {
    if (newVal.length > 0 || isClicked.value) {
      isDataLoading.value = false;
    }
  },
  { immediate: true }
);

//Computed properties for rooms, racks, and rack units
const selectedDatacenterRoomsA = computed(
  () => datacenterStore.rooms[datacenterStore.datacenter] || []
);

const viewLoader = ref(true);

onMounted(async () => {
  window.scrollTo(0, 0);
  isMobile.value = config.globalProperties.$IsMobile();
  if (datacenterStore.isFromvalidationIntra) {
    currentStep.value = datacenterStore.pageNumber;
  }
  if (!datacenterStore.is_my_muffolaR) {
    datacenterStore.is_my_muffolaR = false;
  }
  try {
    if (!datacenterStore.info) {
      // Await the completion of fetchDatacenterInfo and fetchKitInfo

      await Promise.all([datacenterStore.fetchDatacenterInfo()]);
      datacenterStore.infracampus.isInfra = true;
    }
  } catch (e) {
    console.error("Error during fetch operations:", e);
    // Handle errors if needed
  } finally {
    viewLoader.value = false; // Set viewLoader to false after all operations are complete
  }
});

// Watchers
watch(selectedDatacenterId, (newId) => {
  if (newId) {
    datacenterStore.fetchRooms(newId);
    datacenterStore.datacenter = selectedDatacenterId;
  }
});

const handleToggleChanged = (value) => {
  showContent.value = value;
  if (showContent.value === true) {
    datacenterStore.redundancy = true;
    datacenterStore.rmrc = datacenterStore.mrc;
    datacenterStore.rnrc = datacenterStore.nrc;
  } else {
    datacenterStore.redundancy = false;
  }
};

const handleToggleChangedInsideCard = (value) => {
  datacenterStore.is_my_rack_red = value;
  if (datacenterStore.kindOfkit === "is_my_muffola") {
    if (datacenterStore.redundancy) {
      datacenterStore.rbend.is_my_rack = value;
      datacenterStore.is_my_muffolaR = value;
    } else {
      datacenterStore.bend.is_my_rack = value;
      datacenterStore.is_my_muffola = value;
    }
  }
  showContentInside.value = value;
};
const handleRadioSelect = (selectedName) => {
  isClicked.value = true;
  const firstThreeChars = selectedName.slice(0, 3);
  const selectedDataCenter = datacenterStore.info.find((dc) =>
    dc.name.includes(firstThreeChars)
  );
  datacenterStore.boxData = selectedDataCenter;
  if (selectedDataCenter) {
    datacenterStore.fetchKitsXconn(selectedDataCenter.id, 0);
    selectedDatacenterId.value = selectedDataCenter.id;
    isCardSelected.value = true; // Set to true if any data center is selected
    datacenterStore.dc_address = datacenterStore.info[0].address;
    datacenterStore.nameXC = datacenterStore.info[0].name;
  } else {
    isCardSelected.value = false; // Reset if no valid selection
  }
};

const handleRadioSelectKit = (selectedName) => {
  console.log(selectedName);
  isKitSelected.value = true;
  const kitProduct =
    datacenterStore.xConInfo.find(
      (kit) => kit.related_products[0].group_name === selectedName
    ) || {};
  if (kitProduct) {
    const whatRequired = JSON.parse(kitProduct.validation_schema);
    const kindOfKit =
      whatRequired.allOf[1].properties.cross_connect_details.properties.b_end
        .required;
    datacenterStore.infraRequiredFields = kindOfKit;
    if (kindOfKit.length > 0) {
      console.log("kindOfKitg: ", kindOfKit);
      datacenterStore.kitLength = kindOfKit.length;
      datacenterStore.kindOfkit = kindOfKit[0];
    } else {
      datacenterStore.kitLength = 0;
      datacenterStore.kindOfkit = "";
    }
    datacenterStore.kitRelatedProduct = kitProduct;
    datacenterStore.mrc = kitProduct.related_products[0].products[0].price.mrc;
    datacenterStore.nrc = kitProduct.related_products[0].products[0].price.nrc;
    datacenterStore.starter_subscription_time =
      kitProduct.related_products.starter_subscription_time;
    datacenterStore.xc_article = kitProduct.title;
  }
  window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
};

const goToStep = (value) => {
  console.log("entered goToStep with value: ", value);
  // Scroll to the top of the page
  window.scrollTo(0, 0);

  // start validation
  datacenterStore.isValidationCheckInitiated = true;

  // validate Datacenter/Type step
  if (value === 2) {
    // this step should validate if datacenter is selected
    if (!isCardSelected.value && !isKitSelected.value) {
      return;
    }

    // this step should validate if XConn type is selected
    if (isCardSelected.value && !isKitSelected.value) {
      // Reset if no valid selection
      isCardSelected.value = false;
      isDataCenterCardSelected.value = true;
      return;
    }
  }

  // Check if the form is valid when moving from step 3 to step 4
  if (value === 3 && !datacenterStore.infraStep2IsValid) {
    console.log("entered value 3");
    // Form is not valid, do not proceed to the next step
    // Optionally, show an error message or handle it as needed
    return;
  }
  console.log(
    value,
    datacenterStore.infraStep3IsValid,
    datacenterStore.redundancy
  );
  if (
    value === 4 &&
    !datacenterStore.infraStep3IsValid &&
    datacenterStore.redundancy
  ) {
    console.log("entered value 4");
    return;
  }
  if (value === 5) {
    console.log(
      datacenterStore.infraStep2IsValid,
      datacenterStore.infraStep3IsValid
    );
    if (
      datacenterStore.infraStep2IsValid &&
      datacenterStore.infraStep3IsValid
    ) {
      console.log("enter pushing");
      router.push({ name: "CrossConnectValidationInfra" });
      datacenterStore.isValidationCheckInitiated = false;
      return;
    } else {
      return;
    }
  }

  // If validation is passed or not needed, proceed to the desired step
  currentStep.value = value;
  // Reset validation check flag after moving to the next step
  datacenterStore.isValidationCheckInitiated = false;
};
const stepsData = [
  {
    blockedIcon: MyCrossStep1,
    ongoingIcon: CrossStep1InCorso,
    completedIcon: CrossStep1Completato,
    title: "crossConnects.step1Heading",
  },
  {
    blockedIcon: MyCrossStep2,
    ongoingIcon: CrossStep2InCorso,
    completedIcon: CrossStep2Completato,
    title: "crossConnects.step2Heading",
  },
  {
    blockedIcon: MyCrossStep3,
    ongoingIcon: CrossStep3InCorso,
    completedIcon: CrossStep3Completato,
    title: "crossConnects.step3Heading",
  },
  {
    blockedIcon: MyCrossStep4,
    ongoingIcon: CrossStep4InCorso,
    completedIcon: CrossStep4Completato,
    title: "crossConnects.step4Heading",
  },
];
const currentStep = ref(1); // This will track the current step
const listSteps = computed(() => {
  return stepsData.map((step, index) => {
    let icon;
    if (index < currentStep.value - 1) {
      icon = step.completedIcon;
    } else if (index === currentStep.value - 1) {
      icon = step.ongoingIcon;
    } else {
      icon = step.blockedIcon;
    }
    return { ...step, icon };
  });
});

function setStep(value) {
  if (datacenterStore.isCartItem && value === 1) {
    return;
  }
  if (currentStep.value >= value) {
    currentStep.value = value;
  }
}

// const handlePurchaseOrder = (event) => {
//   datacenterStore.purchase_order = event.target.value;
// };

const handleDescription = (event) => {
  datacenterStore.description = event.target.value;
};
</script>

<template>
  <div id="OrderCrossCoverage">
    <div class="mainWrapper">
      <div class="contentSteps">
        <div class="steps">
          <CrossStep
            v-for="(step, index) in listSteps"
            :key="index"
            :index="index"
            :step="currentStep"
            :listStep="step"
            @step-clicked="setStep(index + 1)"
          />
        </div>
      </div>
      <MyLoading :viewLoader="viewLoader" type="" v-if="viewLoader" />
      <div class="contentsForm" v-else>
        <FormsHeader
          :title="$t(`crossConnects.step${currentStep}Heading`)"
          :current-step="currentStep"
          :is-infra="true"
          :isDataCenterCardSelected="isDataCenterCardSelected"
          @toggle-changed="handleToggleChanged"
        />
        <div class="mdl-grid form-container" v-if="!isMobile">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="formWidth">
              <div class="cross-card-root" v-if="currentStep === 1">
                <div
                  class="cards-container-select"
                  v-if="!isDataCenterCardSelected"
                  style="margin-top: 4rem"
                >
                  <CrossCard
                    v-for="dataCenter in datacenterStore.info"
                    :key="dataCenter.id"
                    :id="dataCenter.name"
                    name="data_center"
                    :value="dataCenter.name"
                    :label="`${dataCenter.name}`"
                    :subtitle="`${dataCenter.name} Data Center`"
                    :address="dataCenter.address"
                    :description="`Local ${dataCenter.name}-${
                      dataCenter.name
                    } - ${$t('crossConnects.localC1C2')}`"
                    @radio-selected="handleRadioSelect"
                  />
                </div>
                <div
                  :class="[
                    'cards-container-intra',
                    datacenterStore.xConInfo.length === 1
                      ? 'single-item'
                      : 'multiple-items',
                  ]"
                  v-if="
                    isDataCenterCardSelected &&
                    datacenterStore.xConInfo.length != 0
                  "
                  style="margin-top: 4rem"
                >
                  <CrossCardInfra
                    v-for="xDdataCenter in datacenterStore.xConInfo"
                    :key="xDdataCenter.id"
                    :id="xDdataCenter.related_products[0].products[0].id"
                    name="data_center"
                    :value="xDdataCenter.related_products[0].products[0].title"
                    :label="xDdataCenter.related_products[0].products[0].title"
                    :img="xDdataCenter.img_url"
                    subtitle="Data Center C21 Milano"
                    address="Via Caldera, 21, 20153, Milano"
                    selectable
                    @radio-selected="handleRadioSelectKit"
                    class="cross-card"
                  >
                    <template v-slot:description>
                      <LogoIrveos style="text-align: center" />
                    </template>
                  </CrossCardInfra>
                </div>
                <div v-if="isDataLoading">
                  <MyLoading
                    :viewLoader="isDataLoading"
                    :fix-small-table="true"
                  />
                </div>
                <div
                  v-else-if="datacenterStore.xConInfo.length === 0 && isClicked"
                >
                  <p class="no-item">{{ $t("crossConnects.noItem") }}.</p>
                </div>
              </div>
              <div v-else-if="currentStep === 2">
                <div class="subtitle"></div>
                <div class="sectionTitle" style="margin-top: 2rem">
                  {{ $t("crossConnects.formSubtitleCorrispettives") }}
                </div>
                <div
                  :class="
                    isMobile
                      ? 'flex-container text-style-mobile'
                      : 'flex-container text-style'
                  "
                >
                  <span
                    >{{ $t("crossConnects.activationPrice") }}
                    <span class="second-item" style="font-weight: 400">
                      €{{ useDatacenterStore().nrc }}</span
                    ></span
                  >
                  <span
                    >{{ $t("crossConnects.monthlyPrice") }}
                    <span style="font-weight: 400">
                      €{{ useDatacenterStore().mrc }}</span
                    ></span
                  >
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.aendbend") }}*
                </div>
                <div
                  :class="
                    isMobile ? 'cards-container-mobile' : 'cards-container'
                  "
                >
                  <AEndComponent
                    :form-content="datacenterStore.aend"
                    :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="false"
                  />
                  <div class="arrow-container">
                    <HorisontalArrow class="arrow-icon" />
                  </div>
                  <BEndComponentInfra
                    :kind-of-kit="datacenterStore.kindOfkit"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="false"
                    @toggle-changed="handleToggleChangedInsideCard"
                  />
                </div>
                <div
                  v-if="!datacenterStore.bend.is_my_rack"
                  :class="
                    isMobile
                      ? 'subtitle-container-mobile'
                      : 'subtitle-container'
                  "
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.infoLOAFiles") }}
                  </div>
                  <div class="description-text">
                    {{ $t("crossConnects.authLetter") }}
                  </div>
                </div>
                <div
                  :class="
                    isMobile
                      ? 'subtitle-container-mobile'
                      : 'subtitle-container'
                  "
                >
                  <div
                    v-if="!datacenterStore.bend.is_my_rack"
                    class="subtitle-text"
                  >
                    {{ $t("crossConnects.reqLOAFiles") }}
                  </div>
                  <div
                    v-if="!datacenterStore.bend.is_my_rack"
                    class="description-text"
                  >
                    <p style="margin-top: 1rem">
                      {{ $t("crossConnects.reqLOAtext1") }}
                    </p>
                    <div>
                      {{ $t("crossConnects.reqLOAtext2") }}
                      <ul class="nested-list">
                        <li>
                          {{ $t("crossConnects.nameof") }}
                          <span class="highlight">{{
                            $t("crossConnects.society")
                          }}</span
                          >{{ $t("crossConnects.thatLOADeclares") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.indicationAndPlacement") }}
                          <span class="highlight">B–END</span>
                          {{ $t("crossConnects.andOf") }}
                          <span class="highlight">{{
                            $t("crossConnects.port")
                          }}</span>
                          {{ $t("crossConnects.inWhichConnectionTerminated") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.30daysValidation") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </div>
              <div v-else-if="currentStep === 3">
                <br />
                <br />
                <div class="field-title second" style="margin-top: 4rem">
                  {{ $t("crossConnects.redundancyPossibility") }}
                </div>
                <RedundancyComponent
                  @toggle-changed="handleToggleChanged"
                  :togled="datacenterStore.redundancy"
                  :isMobile="isMobile"
                />
                <div v-if="datacenterStore.redundancy">
                  <div class="separator"></div>
                  <div class="sectionTitle">
                    {{ $t("crossConnects.redundancyActivationCosts") }}
                  </div>
                  <div class="flex-container text-style">
                    <span>{{ $t("crossConnects.activationPrice") }}</span>
                    <span class="second-item" style="font-weight: 400">
                      € {{ datacenterStore.nrc }}</span
                    >
                    <span>{{ $t("crossConnects.monthlyPrice") }}</span>
                    <span style="font-weight: 400">
                      € {{ datacenterStore.mrc }}</span
                    >
                  </div>
                  <div class="separator"></div>
                  <div class="sectionTitle">
                    {{ $t("crossConnects.aendbend") }}*
                  </div>
                  <div class="cards-container">
                    <AEndComponent
                      :form-content="datacenterStore.raend"
                      :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                      :validation-initiated="
                        datacenterStore.isValidationCheckInitiated
                      "
                      :redundant="true"
                    />
                    <div class="arrow-container">
                      <HorisontalArrow class="arrow-icon" />
                    </div>
                    <BEndComponentInfra
                      :kind-of-kit="datacenterStore.kindOfkit"
                      :validation-initiated="
                        datacenterStore.isValidationCheckInitiated
                      "
                      :redundant="true"
                      @toggle-changed="handleToggleChangedInsideCard"
                    />
                  </div>
                  <div
                    v-if="!datacenterStore.rbend.is_my_rack"
                    class="subtitle-container-mobile"
                  >
                    <div class="subtitle-text">
                      {{ $t("crossConnects.infoLOAFiles") }}
                    </div>
                    <div class="description-text">
                      {{ $t("crossConnects.authLetter") }}
                    </div>
                  </div>
                  <div
                    v-if="!datacenterStore.rbend.is_my_rack"
                    class="subtitle-container-mobile"
                  >
                    <div class="subtitle-text">
                      {{ $t("crossConnects.reqLOAFiles") }}
                    </div>
                    <div class="description-text">
                      <p style="margin-top: 1rem">
                        {{ $t("crossConnects.reqLOAtext1") }}
                      </p>
                      <div>
                        {{ $t("crossConnects.reqLOAtext2") }}
                        <ul class="nested-list">
                          <li>
                            {{ $t("crossConnects.nameof") }}
                            <span class="highlight">{{
                              $t("crossConnects.society")
                            }}</span
                            >{{ $t("crossConnects.thatLOADeclares") }}
                          </li>
                          <li>
                            {{ $t("crossConnects.indicationAndPlacement") }}
                            <span class="highlight">B–END</span>
                            {{ $t("crossConnects.andOf") }}
                            <span class="highlight">{{
                              $t("crossConnects.port")
                            }}</span>
                            {{
                              $t("crossConnects.inWhichConnectionTerminated")
                            }}
                          </li>
                          <li>
                            {{ $t("crossConnects.30daysValidation") }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
              </div>
              <div v-else-if="currentStep === 4">
                <!--                <div class="sectionTitle" style="margin-top: 2rem">-->
                <!--                  {{ $t("crossConnects.otherInfo") }}-->
                <!--                </div>-->
                <div
                  class="cards-container"
                  style="text-align: left; border-radius: 4px 4px 0px 0px"
                >
                  <div class="selectable-fields" style="padding: 0">
                    <!--                    <div class="field-title">-->
                    <!--                      {{ $t("crossConnects.order") }}-->
                    <!--                    </div>-->
                    <!--                    <div class="field">-->
                    <!--                      <input-->
                    <!--                        type="text"-->
                    <!--                        name="card1-input3"-->
                    <!--                        id="card1-input3"-->
                    <!--                        v-model="datacenterStore.purchase_order"-->
                    <!--                        @change="handlePurchaseOrder"-->
                    <!--                        class="input-element"-->
                    <!--                      />-->
                    <!--                    </div>-->
                    <div class="field-title">
                      <label>
                        {{ $t("crossConnects.notesAndTechDetails") }}
                      </label>
                      <div>
                        <small style="font-size: 12px">{{
                          $t("fields.maxlength", { len: "255" })
                        }}</small>
                      </div>
                    </div>
                    <div class="field">
                      <textarea
                        type="text"
                        name="card1-input3"
                        id="card1-input3"
                        v-model="datacenterStore.description"
                        @change="handleDescription"
                        class="text-area-element"
                        maxlength="255"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.crossConnectReferrer") }}
                </div>
                <div class="cards-container2">
                  <MyCrossRefTicket />
                </div>
                <div class="separator"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="form-container-mobile"
          v-if="isMobile"
          style="justify-items: center"
        >
          <div class="formWidth">
            <div class="cross-card-root" v-if="currentStep === 1">
              <div
                class="cards-container-select-mobile"
                v-if="!isDataCenterCardSelected"
                style="margin-top: 4rem"
              >
                <CrossCard
                  v-for="dataCenter in datacenterStore.info"
                  :key="dataCenter.id"
                  :id="dataCenter.name"
                  name="data_center"
                  :value="dataCenter.name"
                  :label="`${dataCenter.name}`"
                  :subtitle="`${dataCenter.name} Data Center`"
                  :address="dataCenter.address"
                  :description="`Local ${dataCenter.name}-${dataCenter.name} - Coppia di fibre ottiche`"
                  @radio-selected="handleRadioSelect"
                />
              </div>
              <div
                :class="[
                  'cards-container-intra-mobile',
                  datacenterStore.xConInfo.length === 1
                    ? 'single-item'
                    : 'multiple-items',
                ]"
                v-if="
                  isDataCenterCardSelected &&
                  datacenterStore.xConInfo.length != 0
                "
                style="margin-top: 4rem"
              >
                <CrossCardInfra
                  v-for="xDdataCenter in datacenterStore.xConInfo"
                  :key="xDdataCenter.id"
                  :id="xDdataCenter.related_products[0].products[0].id"
                  name="data_center"
                  :value="xDdataCenter.related_products[0].products[0].title"
                  :label="xDdataCenter.related_products[0].products[0].title"
                  :img="xDdataCenter.img_url"
                  subtitle="Data Center C21 Milano"
                  address="Via Caldera, 21, 20153, Milano"
                  selectable
                  @radio-selected="handleRadioSelectKit"
                  class="cross-card"
                >
                  <template v-slot:description>
                    <LogoIrveos style="text-align: center" />
                  </template>
                </CrossCardInfra>
              </div>
              <div v-if="isDataLoading">
                <MyLoading
                  :viewLoader="isDataLoading"
                  :fix-small-table="true"
                />
              </div>
              <div
                v-else-if="datacenterStore.xConInfo.length === 0 && isClicked"
              >
                <p class="no-item">{{ $t("crossConnects.noItem") }}.</p>
              </div>
            </div>
            <div v-else-if="currentStep === 2">
              <div class="subtitle"></div>
              <div class="sectionTitle" style="margin-top: 2rem">
                {{ $t("crossConnects.formSubtitleCorrispettives") }}
              </div>
              <div
                :class="
                  isMobile
                    ? 'flex-container text-style-mobile'
                    : 'flex-container text-style'
                "
              >
                <span
                  >{{ $t("crossConnects.activationPrice") }}
                  <span class="second-item" style="font-weight: 400">
                    €{{ useDatacenterStore().nrc }}</span
                  ></span
                >
                <span
                  >{{ $t("crossConnects.monthlyPrice") }}
                  <span style="font-weight: 400">
                    €{{ useDatacenterStore().mrc }}</span
                  ></span
                >
              </div>
              <div class="separator"></div>
              <div class="sectionTitle">
                {{ $t("crossConnects.aendbend") }}*
              </div>
              <div
                :class="isMobile ? 'cards-container-mobile' : 'cards-container'"
              >
                <AEndComponent
                  :form-content="datacenterStore.aend"
                  :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                  :validation-initiated="
                    datacenterStore.isValidationCheckInitiated
                  "
                  :redundant="false"
                />
                <div class="arrow-container">
                  <HorisontalArrow class="arrow-icon" />
                </div>
                <BEndComponentInfra
                  :kind-of-kit="datacenterStore.kindOfkit"
                  :validation-initiated="
                    datacenterStore.isValidationCheckInitiated
                  "
                  :redundant="false"
                  @toggle-changed="handleToggleChangedInsideCard"
                />
              </div>
            </div>
            <div v-else-if="currentStep === 3">
              <div class="field-title second">
                {{ $t("crossConnects.redundancyPossibility") }}
              </div>
              <RedundancyComponent
                @toggle-changed="handleToggleChanged"
                :togled="datacenterStore.redundancy"
                :isMobile="isMobile"
              />
              <div v-if="datacenterStore.redundancy">
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.redundancyActivationCosts") }}
                </div>
                <div class="flex-container text-style">
                  <span>{{ $t("crossConnects.activationPrice") }}</span>
                  <span class="second-item" style="font-weight: 400">
                    € {{ datacenterStore.nrc }}</span
                  >
                  <span>{{ $t("crossConnects.monthlyPrice") }}</span>
                  <span style="font-weight: 400">
                    € {{ datacenterStore.mrc }}</span
                  >
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.aendbend") }}*
                </div>
                <div class="cards-container-mobile">
                  <AEndComponent
                    :form-content="datacenterStore.raend"
                    :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="true"
                  />
                  <div class="arrow-container">
                    <HorisontalArrow class="arrow-icon" />
                  </div>
                  <BEndComponentInfra
                    :kind-of-kit="datacenterStore.kindOfkit"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="true"
                    @toggle-changed="handleToggleChangedInsideCard"
                  />
                </div>
                <div
                  v-if="!datacenterStore.rbend.is_my_rack"
                  class="subtitle-container-mobile"
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.infoLOAFiles") }}
                  </div>
                  <div class="description-text">
                    {{ $t("crossConnects.authLetter") }}
                  </div>
                </div>
                <div
                  v-if="!datacenterStore.rbend.is_my_rack"
                  class="subtitle-container-mobile"
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.reqLOAFiles") }}
                  </div>
                  <div class="description-text">
                    <p style="margin-top: 1rem">
                      {{ $t("crossConnects.reqLOAtext1") }}
                    </p>
                    <div>
                      {{ $t("crossConnects.reqLOAtext2") }}
                      <ul class="nested-list">
                        <li>
                          {{ $t("crossConnects.nameof") }}
                          <span class="highlight">{{
                            $t("crossConnects.society")
                          }}</span
                          >{{ $t("crossConnects.thatLOADeclares") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.indicationAndPlacement") }}
                          <span class="highlight">B–END</span>
                          {{ $t("crossConnects.andOf") }}
                          <span class="highlight">{{
                            $t("crossConnects.port")
                          }}</span>
                          {{ $t("crossConnects.inWhichConnectionTerminated") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.30daysValidation") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
            </div>
            <div v-else-if="currentStep === 4">
              <!--              <div class="sectionTitle" style="margin-top: 2rem">-->
              <!--                {{ $t("crossConnects.otherInfo") }}-->
              <!--              </div>-->
              <div
                class="cards-container-mobile"
                style="text-align: left; border-radius: 4px 4px 0px 0px"
              >
                <div class="selectable-fields" style="padding: 0">
                  <!--                  <div class="field-title">{{ $t("crossConnects.order") }}</div>-->
                  <!--                  <div class="field">-->
                  <!--                    <input-->
                  <!--                      type="text"-->
                  <!--                      name="order-input"-->
                  <!--                      id="order-input"-->
                  <!--                      v-model="datacenterStore.purchase_order"-->
                  <!--                      @change="handlePurchaseOrder"-->
                  <!--                      class="input-element-mobile"-->
                  <!--                      sty-->
                  <!--                    />-->
                  <!--                  </div>-->
                  <div class="field-title">
                    <label>
                      {{ $t("crossConnects.notesAndTechDetails") }}
                    </label>
                    <div>
                      <small style="font-size: 12px">{{
                        $t("fields.maxlength", { len: "255" })
                      }}</small>
                    </div>
                  </div>
                  <div class="field">
                    <textarea
                      type="text"
                      name="notes-input"
                      id="notes-input"
                      v-model="datacenterStore.description"
                      @change="handleDescription"
                      class="text-area-element"
                      maxlength="255"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="sectionTitle">
                {{ $t("crossConnects.crossConnectReferrer") }}
              </div>
              <div class="cards-container2">
                <MyCrossRefTicket :is-mobile="isMobile" />
              </div>
              <div class="separator"></div>
            </div>
          </div>
        </div>
        <ButtonsGroupComponent
          v-show="
            !datacenterStore.isCartItem
              ? datacenterStore.xConInfo.length
              : datacenterStore.isCartItem
          "
          :currentStep="currentStep"
          :isMobile="isMobile"
          :isFromValidation="datacenterStore.isFromvalidationIntra"
          @step-button-click="goToStep"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.field-title {
  margin-bottom: 1rem;
}

.error-message {
  color: red;
}

.no-item {
  text-align: center;
  color: #011831;
  font-size: 1.2rem;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.input-element::placeholder {
  color: #8895a7;
  font-family: OpenSansRegular;
  padding-left: 1rem;
}
.input-element-mobile {
  padding-left: 1rem;
  width: 100%;
  height: 60px;
  background: #eef1f4 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b8c4ce;
}

.text-area-element::placeholder {
  color: #8895a7;
  font-family: OpenSansRegular;
  padding-left: 1rem;
}

.cards-container2 {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Adjust the gap between rows as needed */
}

.selectable-fields2 {
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%; /* Ensure it takes the full width within the container */
}
</style>
